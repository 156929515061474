<template>
  <div class="table-wrap">
    <el-table
      :data="list"
      border
      style="width: 100%"
      :row-style="{ height: 0 }"
      :cell-style="{ padding: '8px 0'}"
    >
      <el-table-column prop="tempId" label="ID" align="center" width="60"></el-table-column>
      <el-table-column prop="role_name" label="角色名称" align="center" width="130"></el-table-column>
      <el-table-column prop="limits" label="权限" align="center"></el-table-column>
      <el-table-column
        prop="created_at"
        label="添加时间"
        align="center"
        width="165"
        :formatter="dateFormat"
        sortable
      ></el-table-column>
      <el-table-column prop="status" label="当前状态" align="center" width="80">
        <template slot-scope="scope">
          <el-tag
            size="mini"
            effect="dark"
            :type="scope.row.status ? 'danger' : 'success'"
          >{{ scope.row.status ? '禁用' : '正常' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="110">
        <template slot-scope="scope">
          <el-button
            type="success"
            size="mini"
            icon="el-icon-edit"
            circle
            @click="$emit('edit', scope.row)"
          ></el-button>
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-delete"
            circle
            @click="$emit('delete', scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import format from '@/mixins/date.js';

export default {
  mixins: [format],
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
</style>