<template>
  <div class="main-wrap">
    <!-- 搜索 -->
    <search-form @search="search" @add-role="showStatus = 1"></search-form>
    <!-- 角色列表 -->
    <role-table :list="list" @edit="handleEdit" @delete="handleDel"></role-table>
    <!-- 分页管理 -->
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
      :current-page="currentPage"
    ></el-pagination>
    <!-- 添加角色/编辑角色 -->
    <add-form
      :show-status="showStatus"
      :edit-data="editData"
      @cancel="showStatus = 0"
      @submit-add="submitForm"
    ></add-form>
  </div>
</template>

<script>
import searchForm from './components/search-form';
import roleTable from './components/role-table';
import addForm from './components/add-form';

import service from './api';
import pagination from '@/mixins/pagination';
export default {
  mixins: [pagination],
  data() {
    return {
      list: [],
      page: {},
      showStatus: 0,
      editData: {},
      searchData: {}
    };
  },
  components: {
    searchForm,
    roleTable,
    addForm
  },
  methods: {
    init() {
      let initParams = { pageNum: 1, pageSize: 10 };
      return this.getList(initParams);
    },
    getList(params) {
      // 切换当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;
      return service.getList(params).then(res => {
        let { list = [], page = {} } = res;
        // 提出权限名称
        list.map((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
          let limitsArr = [];
          item.jurisdiction.forEach(el => {
            limitsArr.push(el.name);
          });
          item.limits = limitsArr.join(',');
        });
        this.list = list;
        this.page = page;
      });
    },
    // 搜索
    search(data) {
      let params = Object.assign({ pageNum: 1 }, data);
      this.searchData = data;
      return this.getList(params);
    },
    // 点击编辑
    handleEdit(row) {
      this.editData = row;
      this.showStatus = 2;
    },
    // 表单提交
    submitForm(data) {
      if (this.showStatus === 1) {
        // 添加角色
        return this.handleSubmit(service.add(data));
      } else {
        // 编辑角色
        let params = Object.assign({ roleId: this.editData.id }, data);
        return this.handleSubmit(service.edit(params));
      }
    },
    // 编辑角色
    handleSubmit(r) {
      return r.then(res => {
        this.showStatus = 0;
        this.$message({ type: 'success', message: '操作成功！', offset: 400 });
        this.init();
      });
    },
    // 删除角色
    handleDel(row) {
      this.$alert(`确定将${row.role_name || ''}角色永久删除？`, '提示', { showCancelButton: true }).then(res => {
        return service.del({ roleId: row.id }).then(res => {
          this.$message({ type: 'success', message: '删除成功' });
          this.init();
        });
      });
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
</style>
