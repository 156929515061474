<template>
  <div class="add">
    <el-dialog
      :title="showStatus === 2 ? '编辑角色' : '添加角色'"
      :visible="!!showStatus"
      width="40%"
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
      @open="openDialog"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="80px"
        class="form"
        hide-required-asterisk
      >
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model.trim="form.roleName" placeholder="请输入角色名称"></el-input>
        </el-form-item>
        <el-form-item label="角色状态" prop="status">
          <el-select v-model="form.status" placeholder="角色状态">
            <el-option
              v-for="item in statusOpts"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="权限设置">
          <el-tree
            :data="tree"
            ref="tree"
            node-key="id"
            :default-checked-keys="checkedKeys"
            show-checkbox
          ></el-tree>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/**
 * 参数说明：
 * @showStatus  => 0: 关闭, 1: 添加, 2: 编辑
 * @editData => 被编辑行row
 */
import service from '../api';
export default {
  props: {
    showStatus: {
      type: Number,
      default: 0
    },
    editData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: { roleName: '', status: '' },
      statusOpts: [
        { value: 0, label: '正常' },
        { value: 1, label: '禁用' }
      ],
      rules: {
        roleName: [{ required: true, message: '请填写角色名称', trigger: 'blur' }],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }]
      },
      checkedKeys: [],
      tree: []
    };
  },
  methods: {
    cancel() {
      this.$refs['form'].resetFields();
      this.$refs.tree.setCheckedKeys([]);
      this.$emit('cancel');
    },
    submit() {
      let permits = this.$refs['tree'].getCheckedKeys();
      let check = true;
      this.$refs['form'].validate(res => (check = res));
      if (!check) return;
      if (!permits.length) {
        this.$message({
          type: 'error',
          message: '请勾选权限设置！'
        });
        return;
      }
      let data = Object.assign(this.form, { jurisdictionId: permits.join(',') });
      this.$emit('submit-add', data);
      this.$refs['form'].resetFields();
      this.$refs.tree.setCheckedKeys([]);
    },
    openDialog() {
      if (this.showStatus === 2) {
        this.$nextTick(() => {
          let { form, editData, checkedKeys } = this;
          form.roleName = editData.role_name || '';
          form.status = editData.status;
          this.checkedKeys = editData.jurisdiction_id.split(',');
        });
      }
    },
    // 获取权限设置
    getPermits() {
      return service.getTree().then(res => {
        let tree = [];
        res.forEach(item => {
          let treeItem = {
            id: item.id,
            label: item.name
          };
          if (item.son && item.son.length) {
            // 若有子节点遍历子节点
            let children = [];
            item.son.forEach(el => {
              let child = {
                id: el.id,
                label: el.name
              };
              children.push(child);
            });
            treeItem.children = children;
          }
          tree.push(treeItem);
        });
        this.tree = tree;
      });
    }
  },
  mounted() {
    this.getPermits();
  }
};
</script>

<style lang="scss" scoped>
.add {
  .form {
    width: 80%;
    margin: 0 auto;
    text-align: left;
  }
}
</style>